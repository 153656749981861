<template lang="pug">
include ../../../configs/template
div.seafarerInfoList
  div.col-sm-12
    +data-info('createDate','sailorDocument.created_at')
  div.col-sm-12.col-md-6
    +data-info('fullName','fullName')
  div.col-sm-12.col-md-6
    +data-info('sex','getDirectoryObject({ value: "sex", id: sailorDocument.sex })[valueLang]')
  div.col-sm-12.col-md-6
    +data-info('taxNumber','sailorDocument.tax_number')
  div.col-sm-12.col-md-6
    +data-info('number','sailorDocument.number_document')
  div.col-sm-12.col-md-6
    +data-info('dateBorn','getDateFormat(sailorDocument.birthday)')
  div.col-sm-12.col-md-6
    +data-info('country','getDirectoryObject({ value: "country", id:sailorDocument.country}).value')
  div.col-sm-12.col-md-6
    +data-info('email','sailorDocument.email')
  div.col-sm-12.col-md-6
    +data-info-status('status','statusById(sailorDocument.status_document)[nameLang]', 'getStatus(sailorDocument.status_document)')
  div.col-12
    v-divider
  div(v-if="sailorDocument.comments?.length").col-sm-12.text-left
    div.col-12.pa-0
      h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
      v-divider
      div(v-for="(comment, index) in sailorDocument.comments" :key="comment.id").ml-4
        div
          +data-info('rejectionReasonStatement','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons" }).name_eng')
        div(v-if="checkAccess('backOffice')").pt-2.py-3
          +data-info('createdBy', 'comment.created_by.name')
        div.pt-2.py-3
          +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
        div.pt-2.py-3
          +data-info('reasonComment', 'comment.comment')
        v-divider
</template>

<script>
import { getStatus, getDateFormat } from '@/mixins/main'
import { mapState, mapGetters } from 'vuex'
import { checkAccess } from '@/mixins/permissions'

export default {
  name: 'StatementISCInfo',
  props: {
    comments: { type: Array, default: () => ([]) },
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      checkAccess,
      getDateFormat,
      getStatus
    }
  },
  computed: {
    ...mapGetters(['statusById', 'getDirectoryObject', 'rejectionReasonByID']),
    ...mapState({
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      valueLang: state => (state.main.lang === 'en') ? 'value_eng' : 'value_ukr'
    }),
    fullName () {
      return `${this.sailorDocument.last_name} ${this.sailorDocument.first_name} ${this.sailorDocument.middle_name}`
    },
    verificationRefusalReason () {
      return this.comments.find(item => item.additional_info.cancel_reason)
    },
    verificationRefusalReasonName () {
      return this.rejectionReasonByID(this.verificationRefusalReason.additional_info.reason)[this.nameLang]
    }
  }
}
</script>
